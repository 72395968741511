import React, { Component } from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Highlight,
  Snippet
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import './App.css';
import Grid from "./grid-component"
const searchClient = algoliasearch('R70QAFW2FK', 'ddc27f29b5f3a2717b9fb62bc1bb6120');

class App extends Component {
  render() {
    return (
      <div>
        <div className="container">
          <InstantSearch searchClient={searchClient} indexName="westerntractor_machinefinder_feed">
            <div className="search-panel">
              <div className="search-panel__results">
                <SearchBox   
                  className="searchbox"
                  autoFocus={true}
                  translations={{
                    placeholder: '',
                  }}
                />
                <Hits hitComponent={Hit} />

                <div className="pagination">
                  <Pagination />
                </div>
              </div>
            </div>
          </InstantSearch>
        </div>
      </div>
    );
  }
}

function Hit(props) {
  console.log(props)
  // const description = (<Highlight attribute="description" hit={props.hit} />)
  // const model = (<Highlight attribute="model" hit={props.hit} />)
  // const category = (<Highlight attribute="category" hit={props.hit} />)
  const image = props.hit.images.image && props.hit.images.image[0].filePointerLarge;
  return (
    <Grid Snippet={Snippet} Highlight={Highlight} hit={props.hit} image={image}  />
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default App;
