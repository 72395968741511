import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import def from "./140x100.png"

const useStyles = makeStyles((theme) => ({
  root: {
      height:'100%',
      alignItems:'baseline',
    maxHeight:'800px',
    border:'none',
    margin:'5px'
  },
  paper: {
    padding: theme.spacing(3),
    margin: 'auto',
    maxWidth: 500,
    flexWrap:'wrap',
    border:'none',
    display: 'flex',
    justifyContent:' space-evenly',
    height: '100%',
    alignContent: 'flex-start'
  },
  image: {
    height:'150px',
    margin:'auto',
    textAlign:'center'
  },
  buttonWrap: {
marginTop:'20px'
  },
  description:{
    whiteSpace: 'nowrap', 
    width: '50px', 
    overflow: 'ellipsis',
    textOverflow: 'clip',
    border:'none' 
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  container:{
      flexWrap:'nowrap',
      border:'none',
      placeItems: 'center'
  },
  basis:{
      flexBasis:'auto'
  }
}));

export default function ComplexGrid(props) {
  const classes = useStyles();
  const Highlight = props.Highlight;
  const Snippet = props.Snippet;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container className={classes.container} spacing={2} >
          <Grid  xs={12} item className={classes.basis} >
            <ButtonBase className={classes.image} >
              <img className={classes.img +' card'} alt="complex" src={props.image || def} />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm className={classes.basis} container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs >
                <Typography gutterBottom variant="subtitle1">
                Manufacturer: <Highlight attribute="manufacturer" hit={props.hit} />
                </Typography>
                <Typography  component={'p'} variant="body2" gutterBottom>
                Model: <Highlight attribute="model" hit={props.hit} />
                </Typography>
                <Typography variant="body2" color="textSecondary">
                Model Year: <Highlight  attribute="modelYear" hit={props.hit} />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid  xs={12} item className={classes.buttonWrap} >
                <Typography variant="body2" color="textSecondary">
                Description: <Snippet  attribute="description" hit={props.hit} /><span style={{fontWeight:'bolder'}}>...</span>
                </Typography>
          </Grid>
      </Paper>
    </div>
  );
}
/**
 * .MuiPaper-elevation1.MuiPaper-rounded {
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    align-content: flex-start;
}

.makeStyles-root-1 {
    height: 100%;
    align-items: baseline;
}
 */